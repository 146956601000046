import { schema } from 'normalizr';

import { Section, Category, MatchingChoice } from 'models/section';

export const IMAGE_REGEX = /^!\[(\w+)\]\((.+)\)$/;
export const MATH_REGEX = /^\[% (.*) %\]$/;
export const LETTER_LABEL_CHARCODE_MIN = 'a'.charCodeAt(0);
export const LETTER_LABEL_CHARCODE_MAX = 'z'.charCodeAt(0);
export const MAX_ANSWERS_COUNT = 10;
export const MAX_GROUPED_CHOICE_ANSWERS_COUNT = 50;
export const MAX_ANSWER_CATEGORIES_COUNT = 10;
export const NUMBER_LABEL_MIN = 1;
export const NUMBER_LABEL_MAX = 99;
export const DEFAULT_ANSWER_OPTIONS = {
  case_sensitive: false,
  allow_special_characters: false,
  enable_numbering: false,
  numbering_bracket: '()' as const,
  numbering_format: 'number' as const,
  numbering_start: '1',
};

export type MediaType = 'image' | 'audio';
export interface MediaPayload {
  id: string;
  url: string;
  type: MediaType;
}

export type AnswerListType = 'number' | 'alphabet' | 'custom';
export type AnswerListBracket = '()' | '[]' | '{}' | 'none';
export enum AnswerType {
  MultipleChoice = 'multiple_choice',
  ExactValue = 'exact_value',
  Categorise = 'categorise',
  CorrectOrder = 'correct_order',
  GroupedChoices = 'grouped_choices',
  MatchingType = 'matching_choices',
  TrueOrFalse = 'true_or_false',
}

export interface Answer {
  id?: string;
  content: string;
  content_html?: string;
  correct: boolean;
  numbering?: string;
  answer_category_id?: string | null;
  answer_matching_choice_ids: Array<string | undefined>;
  temporary_category_id?: string;
  temporary_matching_choice_ids?: Array<string | undefined> | null;
  // client-side only
  // identifies answers that are not saved yet (no id)
  temporaryId?: string;
  // preserves answer expanded or collapsed state
  isOpen?: boolean;
  hasFocus?: boolean;
}

export type PointsType = 'question' | 'answer';

export interface Question {
  id: string;
  text: string;
  name: string;
  topic_id: string;
  answer_type: AnswerType;
  answer_categories: Category[];
  answer_matching_choices: MatchingChoice[];
  last_saved_at?: string;
  published_at?: string;
  sections: Section[];
  explanation?: {
    id: string;
    sections: Section[];
  };
  errors: string[];

  hint?: {
    sections: Section[];
  };
  preceding_question_id?: string | null;
  succeeding_question_id?: string;
  // TODO: Possible API refactor
  // creating question payload uses `hints` array
  // but outputs a `hint` object
  hints?: Section[];
  passage_id?: string;
  answers: Answer[];
  case_sensitive: boolean;
  allow_special_characters: boolean;
  enable_numbering: boolean;
  numbering_format: AnswerListType;
  numbering_bracket: AnswerListBracket;
  numbering_start: string;
  original_topic_id?: string;
  author: boolean;
  has_linked_questions?: boolean;
  reorder_group_ids?: string[];
  position: number;
  enable_file_upload: boolean;
  file_upload_options?: {
    enabled: boolean;
    mandatory: boolean;
    categories: string[];
  };

  // flexible points system
  points: number;
  points_type: PointsType;
  // should be used getting the points of question
  // points will now be used mostly for PPA (Point Per Answer)
  // eg. 3 correct answers
  // points_type = 'answer'
  // points = 5
  // total_points = 15
  // -----
  // points_type = 'question'
  // points = 5
  // total_points = 5
  total_points: number;

  // The result of squashing sections. These properties are populated in question
  // selector
  content?: string;
  content_html?: string;
  explanationContent?: string;
  hintContent?: string;

  linked_question_ids?: string[];
  preceding_question_position?: number;
  succeeding_question_position?: number;

  translate_answers: boolean;

  matching_choice_premise_lang?: string;
}

export interface DraftQuestion extends Omit<Question, 'topic_id'> {
  topic_id?: string;
}

export const questionSchema = new schema.Entity('questions');
