import { Schema, schema } from 'normalizr';

import { User, userSchema } from 'models/user';
import { Team } from 'models/team';
import { Subject, subjectSchema } from 'models/subject';
import { CourseTag } from 'models/courseTag';
import { courseTagSchema } from 'models/course';

type Permissions = 'create' | 'edit' | 'view' | 'publish';
export enum Permission {
  Create = 'create',
  View = 'view',
  Edit = 'edit',
  Publish = 'publish',
}
export enum CountryCode {
  Indonesia = 'ID',
  Philippines = 'PH',
}

type Features = 'manage_assessments' | 'upload_question_bulk';
export enum Feature {
  ManageAssessments = 'manage_assessments',
  UploadQuestionBulk = 'upload_question_bulk',
}

export interface BaseOrganization {
  id: string;
  name: string;
  admin: boolean;
  restricted: boolean;
  role?: string;
  use_teams_in_qcreate: boolean;
  permissions: Permissions[];
  teams: Team[];
  can_import_license_questions: boolean;
  alliance: boolean;
  enable_autosave?: boolean;
  enable_point_system?: boolean;
  enable_point_per_answer?: boolean;
  enable_file_upload?: boolean;
  disable_file_type?: boolean;
  enable_matching_choices?: boolean;
  features: Features[];
  verified: boolean;
}

export interface Organization extends BaseOrganization {
  members?: User[];
  subjects: Subject[];
  course_tags?: CourseTag[];
  country_code: string;
  country_subjects: Subject[];
}

export interface NormalizedOrganization extends BaseOrganization {
  members?: string[];
  subjects: string[];
  course_tags?: string[];
  country_subjects: string[];
}

const definitions = {
  members: [userSchema],
  course_tags: [courseTagSchema],
  subjects: [subjectSchema],
  country_subjects: [subjectSchema],
} as Schema;

export const organizationSchema = new schema.Entity(
  'organizations',
  definitions,
);
