import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';

import Navbar from 'components/organisms/Navbar';
import ContentWithSidebarContainer from 'components/organisms/Sidebar/ContentWithSidebarContainer';
import CourseTreeSidebar from 'components/organisms/Sidebar/CourseTreeSidebar';
import { getLocale } from 'utils/i18n';
import { getIsUserRestricted } from 'selectors/organizations';

const PageWithSidebar = ({
  children,
}: { children: React.ReactNode } & RouteComponentProps) => {
  const location = useLocation();
  const isUserRestricted = useSelector(getIsUserRestricted);
  const pathname = location.pathname.replace(`/${getLocale()}`, '');

  const showSidebar = [
    new RegExp('^/courses/\\w+$'),
    new RegExp('^/bundles/\\w+$'),
    new RegExp('^/topics/\\w+$'),
    new RegExp('^/topics/\\w+/questions$'),
  ].some(pathRegex => !!pathname.match(pathRegex));

  const withoutNavbarAndFooter = [
    new RegExp('/chapters/new$'),
    new RegExp('/questions/new$'),
    new RegExp('/passages/new$'),
    new RegExp('/import$'),
    new RegExp('/excel$'),
  ];

  if (withoutNavbarAndFooter.some(pathRegex => !!pathname.match(pathRegex))) {
    return <div className="flex flex-col min-h-screen">{children}</div>;
  }

  // No navbar
  if (
    [new RegExp('/(un)?publish$')].some(
      pathRegex => !!pathname.match(pathRegex),
    )
  ) {
    return <div className="flex flex-col min-h-screen">{children}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar withNavigation />

      {showSidebar ? (
        <ContentWithSidebarContainer className="flex-grow">
          {!isUserRestricted && <CourseTreeSidebar />}
          <div className="w-full">{children}</div>
        </ContentWithSidebarContainer>
      ) : (
        [children]
      )}
    </div>
  );
};

export default PageWithSidebar;
